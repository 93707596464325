import {NotificationManager} from "react-notifications";

export function addToast(type, title, message){
  switch(type){
    case "success":
      NotificationManager.success(message, title, 3000);
      break;
    case "error":
      NotificationManager.error(message, title, 3000);
      break;
    default:
      break;
  }
}
