import React from 'react';

import { Route, Switch } from 'react-router-dom'
import './index.css';
import * as image from './assets'
import CustomerCheckout from './pages/CustomerCheckout'

function App() {

  return (
    <div className="App">
      <Switch>
        <Route path="/" exact component={CustomerCheckout} />
      </Switch>
    </div>
  );
}

export default App;
