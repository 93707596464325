import React, { useState, useEffect } from "react";
import CurrencyInput from "react-currency-input";
import InputMask from "react-input-mask";

import ModalCCV from "../ModalCCV";
import { addToast } from "../Notification";
import Cards from "react-credit-cards";
import "react-credit-cards/es/styles-compiled.css";
import { codigoPaises } from "../../utils";

import {
  toReal,
  validCheckout,
  checkCardBrand,
  validAccount,
} from "../../utils";
import * as image from "../../assets";

function Checkout({
  disabled,
  disableSameTaxpayer,
  price,
  transaction,
  taxpayer,
  handleUpdate,
  courseClass,
}) {
  const [payload, setPayload] = useState({
    name: "",
    name_tag: "",
    email: "",
    phone: "",
    taxpayer: "",
    holder_name: "",
    exp_month: "",
    exp_year: "",
    number: "",
    cvc: "",
    amount: 0,
    installment_number: "1",
    brand: "",
    holder_taxpayer: "",
  });
  const [confirmEmail, setConfirmEmail] = useState("");
  const year = new Date().getFullYear();
  const [isSameTaxpayer, setIsSameTaxpayer] = useState(false);
  const [modal, setModal] = useState(false);
  const [focused, setFocused] = useState("");

  useEffect(() => {
    if (price) {
      setPayload({ ...payload, amount: price });
    } else if (transaction) {
      setPayload({
        ...payload,
        amount: transaction.value,
        installment_number:
          transaction.installments > 0 ? transaction.installments : "1",
      });
    }
  }, [price, transaction]);

  const handleChangeValue = (field, value) => {
    if (field === "confirmEmail") {
      setConfirmEmail(value);
    } else {
      setPayload({ ...payload, [field]: value });
    }
  };

  const changeValue = (e) => {
    if (payload.name) {
      let name = payload.name.split(" ");
      if (name.length > 0) {
        setPayload({
          ...payload,
          name_tag: `${name[0]} ${name[name.length - 1]}`,
        });
      } else {
        setPayload({ ...payload, name_tag: name[0] });
      }
    }
  };

  const handleTriggerModal = () => setModal(!modal);

  const formatMonth = (value) => {
    const _month = value < 10 ? "0" + value : value.toString();
    return _month;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const checkCard = checkCardBrand(payload.number);

    var newTaxpayer = "";
    if (isSameTaxpayer && transaction) {
      newTaxpayer = transaction.inscription.trainee.taxpayer.replace("-", "");
    } else if (isSameTaxpayer && disableSameTaxpayer) {
      newTaxpayer = disableSameTaxpayer.taxpayer.replace("-", "");
    } else if (isSameTaxpayer) {
      newTaxpayer = payload.taxpayer.replace("-", "");
    } else {
      newTaxpayer = payload.holder_taxpayer.replace("-", "");
    }

    let checkoutPayload = {
      ...payload,
      exp_month: formatMonth(payload.exp_month),
      brand: checkCard ? checkCard : "",
      holder_taxpayer: newTaxpayer,
    };

    let valid = validCheckout(checkoutPayload);
    if (!valid.status) {
      return addToast("error", "Erro", valid.message);
    }
    if (validateEmail()) {
      return handleUpdate(checkoutPayload);
    }
  };

  const changeFocus = (e) => {
    setFocused(e.target.name);
  };

  const validateEmail = () => {
    if (payload.email !== confirmEmail) {
      return addToast("error", "Erro", "Confirmação de email não corresponde.");
    } else {
      return true;
    }
  };

  const _CURRENT_YEAR = new Date().getFullYear();

  return (
    <>
      <form
        autoComplete="off"
        onSubmit={
          transaction
            ? (event) => handleSubmit(event)
            : disabled
            ? () => {}
            : (event) => handleSubmit(event)
        }
        className=""
      >
        <div className="box">
          {/* <CurrencyInput
                        prefix='R$ '
                        onChangeEvent={(event, mask, value) => {
                            handleChangeValue('amount', value)
                        }}
                        value={payload.amount}
                        disabled
                    /> */}

          <div className="personal">
            <input
              value={payload.name}
              type="text"
              placeholder="Nome Completo"
              onChange={({ target }) => handleChangeValue("name", target.value)}
              onBlur={changeValue}
            />
            {/* <input
              value={payload.name_tag}
              type="text"
              placeholder="Crachá"
              onChange={({ target }) =>
                handleChangeValue("name_tag", target.value)
              }
            /> */}

            <input
              value={payload.email}
              placeholder="E-mail"
              type="email"
              onChange={({ target }) =>
                handleChangeValue("email", target.value)
              }
            />
            <input
              value={confirmEmail}
              placeholder="Confirme seu e-mail"
              type="email"
              onChange={({ target }) =>
                handleChangeValue("confirmEmail", target.value)
              }
              onBlur={validateEmail}
            />
            <InputMask
              value={payload.taxpayer}
              placeholder="CPF"
              mask="999999999-99"
              type="tel"
              onChange={({ target }) =>
                handleChangeValue("taxpayer", target.value)
              }
              onBlur={changeValue}
            />
            <div className="rowPhone">
              <select>
                {codigoPaises.map((item) => (
                  <option selected={item === "+55"}>{item}</option>
                ))}
              </select>
              <InputMask
                value={payload.phone}
                placeholder="Celular com DDD"
                mask="(99)99999-9999"
                type="tel"
                onChange={({ target }) =>
                  handleChangeValue("phone", target.value)
                }
              />
            </div>
          </div>
          <div className="card">
            <div className="rowCard">
              <div>
                <input
                  type="tel"
                  placeholder="Número do Cartão"
                  onChange={({ target }) =>
                    handleChangeValue("number", target.value)
                  }
                  name="number"
                  onFocus={changeFocus}
                />
                <input
                  placeholder="Nome do Titular do Cartão"
                  type="text"
                  onChange={({ target }) =>
                    handleChangeValue("holder_name", target.value)
                  }
                  name="name"
                  onFocus={changeFocus}
                />

                <div className="row-input">
                  <div className="customSelectContainer">
                    <select
                      style={{ marginRight: 10 }}
                      className="customSelect"
                      name="expiry"
                      onChange={({ target }) =>
                        handleChangeValue("exp_month", target.value)
                      }
                      onFocus={changeFocus}
                    >
                      <option selected disabled hidden>
                        MÊS
                      </option>
                      <option>1</option>
                      <option>2</option>
                      <option>3</option>
                      <option>4</option>
                      <option>5</option>
                      <option>6</option>
                      <option>7</option>
                      <option>8</option>
                      <option>9</option>
                      <option>10</option>
                      <option>11</option>
                      <option>12</option>
                    </select>
                    <select
                      className="customSelect"
                      name="expiry"
                      onChange={({ target }) =>
                        handleChangeValue("exp_year", target.value)
                      }
                      onFocus={changeFocus}
                    >
                      <option selected disabled hidden>
                        ANO
                      </option>
                      {Array.from(Array(15), (_, index) => (
                        <option
                          key={_CURRENT_YEAR + index}
                          value={_CURRENT_YEAR + index}
                        >
                          {_CURRENT_YEAR + index}
                        </option>
                      ))}
                    </select>
                    <div className="cvvInput">
                      <input
                        type="tel"
                        placeholder="CVV"
                        maxLength={4}
                        name="cvc"
                        onChange={({ target }) =>
                          handleChangeValue("cvc", target.value)
                        }
                        onFocus={changeFocus}
                      />
                      <img
                        onClick={() => setModal(true)}
                        src={image.INFOR}
                        id="infor-icon"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <Cards
                  name={payload.holder_name}
                  number={payload.number}
                  expiry={`${payload.exp_month}/${payload.exp_year}`}
                  cvc={payload.cvc}
                  focused={focused}
                />
              </div>
            </div>
            <div>
              {!isSameTaxpayer && (
                <InputMask
                  placeholder="CPF do Titular do Cartão"
                  mask="999999999-99"
                  type="tel"
                  onChange={({ target }) =>
                    handleChangeValue("holder_taxpayer", target.value)
                  }
                  onFocus={changeFocus}
                />
              )}

              {!disableSameTaxpayer && (
                <fieldset className="inputGroup">
                  <input
                    id="isSameTaxpayer"
                    name="isSameTaxpayer"
                    type="checkbox"
                    value={isSameTaxpayer}
                    onChange={({ target }) => setIsSameTaxpayer(target.checked)}
                    onFocus={changeFocus}
                  />
                  <label htmlFor="isSameTaxpayer">Mesmo CPF do Treinando</label>
                </fieldset>
              )}

              <select
                className="customSelect"
                onChange={({ target }) =>
                  handleChangeValue("installment_number", target.value)
                }
                onFocus={changeFocus}
              >
                {transaction ? (
                  <>
                    {transaction.installments >= 1 ||
                    transaction.installments === "" ||
                    transaction.installments === "0" ? (
                      <option
                        value={1}
                        selected={
                          transaction.installments === "1" ? true : false
                        }
                      >
                        {price
                          ? `1x de ${toReal(parseFloat(price))}`
                          : `1x de ${toReal(parseFloat(transaction.value))}`}
                      </option>
                    ) : null}
                    {transaction.installments >= 2 ||
                    transaction.installments === "" ||
                    transaction.installments === "0" ? (
                      <option
                        value={2}
                        selected={
                          transaction.installments === "2" ? true : false
                        }
                      >
                        {price
                          ? `2x de ${toReal(price / 2)}`
                          : `2x de ${toReal(transaction.value / 2)}`}
                      </option>
                    ) : null}
                    {transaction.installments >= 3 ||
                    transaction.installments === "" ||
                    transaction.installments === "0" ? (
                      <option
                        value={3}
                        selected={
                          transaction.installments === "3" ? true : false
                        }
                      >
                        {price
                          ? `3x de ${toReal(price / 3)}`
                          : `3x de ${toReal(transaction.value / 3)}`}
                      </option>
                    ) : null}
                    {transaction.installments >= 4 ||
                    transaction.installments === "" ||
                    transaction.installments === "0" ? (
                      <option
                        value={4}
                        selected={
                          transaction.installments === "4" ? true : false
                        }
                      >
                        {price
                          ? `4x de ${toReal(price / 4)}`
                          : `4x de ${toReal(transaction.value / 4)}`}
                      </option>
                    ) : null}
                    {transaction.installments >= 5 ||
                    transaction.installments === "" ||
                    transaction.installments === "0" ? (
                      <option
                        value={5}
                        selected={
                          transaction.installments === "5" ? true : false
                        }
                      >
                        {price
                          ? `5x de ${toReal(price / 5)}`
                          : `5x de ${toReal(transaction.value / 5)}`}
                      </option>
                    ) : null}
                    {transaction.installments >= 6 ||
                    transaction.installments === "" ||
                    transaction.installments === "0" ? (
                      <option
                        value={6}
                        selected={
                          transaction.installments === "6" ? true : false
                        }
                      >
                        {price
                          ? `6x de ${toReal(price / 6)}`
                          : `6x de ${toReal(transaction.value / 6)}`}
                      </option>
                    ) : null}
                    {transaction.installments >= 7 ||
                    transaction.installments === "" ||
                    transaction.installments === "0" ? (
                      <option
                        value={7}
                        selected={
                          transaction.installments === "7" ? true : false
                        }
                      >
                        {price
                          ? `7x de ${toReal(price / 7)}`
                          : `7x de ${toReal(transaction.value / 7)}`}
                      </option>
                    ) : null}
                    {transaction.installments >= 8 ||
                    transaction.installments === "" ||
                    transaction.installments === "0" ? (
                      <option
                        value={8}
                        selected={
                          transaction.installments === "8" ? true : false
                        }
                      >
                        {price
                          ? `8x de ${toReal(price / 8)}`
                          : `8x de ${toReal(transaction.value / 8)}`}
                      </option>
                    ) : null}
                    {transaction.installments >= 9 ||
                    transaction.installments === "" ||
                    transaction.installments === "0" ? (
                      <option
                        value={9}
                        selected={
                          transaction.installments === "9" ? true : false
                        }
                      >
                        {price
                          ? `9x de ${toReal(price / 9)}`
                          : `9x de ${toReal(transaction.value / 9)}`}
                      </option>
                    ) : null}
                    {transaction.installments >= 10 ||
                    transaction.installments === "" ||
                    transaction.installments === "0" ? (
                      <option
                        value={10}
                        selected={
                          transaction.installments === "10" ? true : false
                        }
                      >
                        {price
                          ? `10x de ${toReal(price / 10)}`
                          : `10x de ${toReal(transaction.value / 10)}`}
                      </option>
                    ) : null}
                    {transaction.installments >= 11 ? (
                      <option
                        value={11}
                        selected={
                          transaction.installments === "11" ? true : false
                        }
                      >
                        {price
                          ? `11x de ${toReal(price / 11)}`
                          : `11x de ${toReal(transaction.value / 11)}`}
                      </option>
                    ) : null}
                    {transaction.installments >= 12 ? (
                      <option
                        value={12}
                        selected={
                          transaction.installments === "12" ? true : false
                        }
                      >
                        {price
                          ? `12x de ${toReal(price / 12)}`
                          : `12x de ${toReal(transaction.value / 12)}`}
                      </option>
                    ) : null}
                  </>
                ) : (
                  <>
                    <option value={1}>
                      {price
                        ? `1x de ${toReal(parseFloat(price))}`
                        : `1x de ${toReal(parseFloat(transaction.value))}`}
                    </option>
                    <option value={2}>
                      {price
                        ? `2x de ${toReal(price / 2)}`
                        : `2x de ${toReal(transaction.value / 2)}`}
                    </option>
                    <option value={3}>
                      {price
                        ? `3x de ${toReal(price / 3)}`
                        : `3x de ${toReal(transaction.value / 3)}`}
                    </option>
                    <option value={4}>
                      {price
                        ? `4x de ${toReal(price / 4)}`
                        : `4x de ${toReal(transaction.value / 4)}`}
                    </option>
                    <option value={5}>
                      {price
                        ? `5x de ${toReal(price / 5)}`
                        : `5x de ${toReal(transaction.value / 5)}`}
                    </option>
                    <option value={6}>
                      {price
                        ? `6x de ${toReal(price / 6)}`
                        : `6x de ${toReal(transaction.value / 6)}`}
                    </option>
                    <option value={7}>
                      {price
                        ? `7x de ${toReal(price / 7)}`
                        : `7x de ${toReal(transaction.value / 7)}`}
                    </option>
                    <option value={8}>
                      {price
                        ? `8x de ${toReal(price / 8)}`
                        : `8x de ${toReal(transaction.value / 8)}`}
                    </option>
                    <option value={9}>
                      {price
                        ? `9x de ${toReal(price / 9)}`
                        : `9x de ${toReal(transaction.value / 9)}`}
                    </option>
                    <option value={10}>
                      {price
                        ? `10x de ${toReal(price / 10)}`
                        : `10x de ${toReal(transaction.value / 10)}`}
                    </option>
                  </>
                )}
              </select>
            </div>
          </div>
          <div className="details">
            <span>Detalhes da Compra</span>
            <hr />
            <div>
              <p>{courseClass.name}</p>
              <p>{toReal(+price)}</p>
            </div>
          </div>
          <div className="confirmButtonContainer">
            <button
              disabled={transaction ? false : disabled}
              className="confirm-button"
            >
              Pagar agora
            </button>
          </div>
        </div>
      </form>
      <div className="footer">
        <p>Mutase &copy; {year} - Todos os direitos reservados</p>
      </div>
      <ModalCCV modal={modal} triggerModal={handleTriggerModal} />
    </>
  );
}

export default Checkout;
