
import provider from "./config";

export const getCourseClass = async (id) => {
    try {
        const response = await provider.get(`class/${id}/`);
        return response.data;
    } catch (err) {
        throw Error("Turma inválida");
    }
}