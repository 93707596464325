import React from 'react';

function CourseInformationTransaction({ transaction }) {
  return (
    <div className="courseInfoContainer">
      <div className="courseInfoGroup">
        <strong>Turma</strong>
        <p>{transaction.inscription.course_class.name}</p>
      </div>
      {/* <div className="courseInfoGroup">
        <strong>Head Trainer</strong>
        <p>{transaction.inscription.course_class.head_trainer.name}</p>
      </div> */}
      <div className="courseInfoGroup">
        <strong>Valor da Inscrição</strong>
        <p>R$ {transaction.inscription.course_class.reference_value}</p>
      </div>
      <div className="courseInfoGroup">
        <strong>Valor à pagar</strong>
        <p>R$ {transaction.value}</p>
      </div>
    </div>
  );
}

export default CourseInformationTransaction;