//zoop urls and keys
export const ZOOP_MARKETPLACE_ID = "ed43c0f901484831974d19409643104c";
export const AUTH_ZOOP_KEY =
  "Basic enBrX3Byb2RfSHY3TkxkSjQ2U3EwSFRRQkkyYlBSZzd2Og==";
export const API_ZOOP_URL =
  "https://api.zoop.ws/v1/marketplaces/ed43c0f901484831974d19409643104c";
export const API_ZOOP_BASE = "https://api.zoop.ws/v1";

// export const BASE_URL = 'https://pay.devari.com.br/api/v1';
export const BASE_URL = "https://pay-dev.devari.com.br/api/v1";

export const PAYMENT_URL = `${BASE_URL}/checkout_multi/`;
export const GENERATE_TOKEN_URL = `${BASE_URL}/product`;

export const APP_ID = "Zhk5X1QJ";

// export const APP_TOKEN = "d41b413d803b6814b322c49ff69e7c2c0c905bf1";
export const APP_TOKEN = "7637eb6f6af35099776aad1dc1f664c173420ce8";

const EMAIL_REGEX = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

//https://gist.github.com/claudiosanches/26d9668f21dbdc787472
const brands = [
  {
    name: "elo",
    regex:
      /^(40117(8|9))|(431274)|(438935)|(451416)|(457393)|(45763(1|2))|(504175)|(627780)|(636297)|(636368)|(65500(0|1))|(65165[2-4])|(65048[5-8])|(65500(2|3))|(650489)|(65049[0-4])|(506699|5067[0-6][0-9]|50677[0-8])|(509[0-8][0-9]{2}|5099[0-8][0-9]|50999[0-9])|(65003[1-3])|(65003[5-9]|65004[0-9]|65005[01])|(65040[5-9]|6504[1-3][0-9])|(65048[5-9]|65049[0-9]|6505[0-2][0-9]|65053[0-8])|(65054[1-9]|6505[5-8][0-9]|65059[0-8])|(65070[0-9]|65071[0-8])|(65072[0-7])|(65090[1-9]|65091[0-9]|650920)|(65165[2-9]|6516[67][0-9])|(65500[0-9]|65501[0-9])|(65502[1-9]|6550[34][0-9]|65505[0-8])$/,
  },
  { name: "hipercard", regex: /^(606282\d{10}(\d{3})?)|(3841\d{15})$/ },
  { name: "amex", regex: /^3[47]\d{13}$/ },
  { name: "mastercard", regex: /^(5[1-5]\d{4}|677189)\d{10}$/ },
  { name: "visa", regex: /^4\d{12}(\d{3})?$/ },
  { name: "jcb", regex: /^(?:2131|1800|35\d{3})\d{11}$/ },
  { name: "aura", regex: /^(5078\d{2})(\d{2})(\d{11})$/ },
];

export const toBase64 = (file) =>
  new Promise((resolver, rej) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolver(reader.result);
    reader.onerror = (error) => PromiseRejectionEvent(error);
  });

export function toReal(number, delivery = true) {
  //this function convert a price number to default price conversion.
  //the "delivery" params is require if value is not to convert delivery price
  if (typeof number !== "number") return "";
  if (number > 0) return `R$ ${number.toFixed(2).replace(".", ",")}`;
  else return "R$ 0,00";
}

export const validCPF = (cpf) => {
  //https://gist.github.com/roneigebert/10d788a07e2ffff88eb0f1931fb7bb49
  if (
    !cpf ||
    cpf.length !== 11 ||
    cpf === "00000000000" ||
    cpf === "11111111111" ||
    cpf === "22222222222" ||
    cpf === "33333333333" ||
    cpf === "44444444444" ||
    cpf === "55555555555" ||
    cpf === "66666666666" ||
    cpf === "77777777777" ||
    cpf === "88888888888" ||
    cpf === "99999999999"
  )
    return false;
  let soma = 0;
  let resto;
  for (let i = 1; i <= 9; i++)
    soma = soma + parseInt(cpf.substring(i - 1, i)) * (11 - i);
  resto = (soma * 10) % 11;
  if (resto === 10 || resto === 11) resto = 0;
  if (resto !== parseInt(cpf.substring(9, 10))) return false;
  soma = 0;
  for (let i = 1; i <= 10; i++)
    soma = soma + parseInt(cpf.substring(i - 1, i)) * (12 - i);
  resto = (soma * 10) % 11;
  if (resto === 10 || resto === 11) resto = 0;
  if (resto !== parseInt(cpf.substring(10, 11))) return false;
  return true;
};

export const validCNPJ = (cnpj) => {
  //https://www.geradorcnpj.com/javascript-validar-cnpj.htm
  if (cnpj === "") return false;

  if (cnpj.length !== 14) return false;

  // Elimina CNPJs invalidos conhecidos
  if (
    cnpj === "00000000000000" ||
    cnpj === "11111111111111" ||
    cnpj === "22222222222222" ||
    cnpj === "33333333333333" ||
    cnpj === "44444444444444" ||
    cnpj === "55555555555555" ||
    cnpj === "66666666666666" ||
    cnpj === "77777777777777" ||
    cnpj === "88888888888888" ||
    cnpj === "99999999999999"
  )
    return false;

  // Valida DVs
  let tamanho = cnpj.length - 2;
  let numeros = cnpj.substring(0, tamanho);
  let digitos = cnpj.substring(tamanho);
  let soma = 0;
  let pos = tamanho - 7;
  for (let i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--;
    if (pos < 2) pos = 9;
  }
  let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
  if (resultado !== digitos.charAt(0)) return false;

  tamanho = tamanho + 1;
  numeros = cnpj.substring(0, tamanho);
  soma = 0;
  pos = tamanho - 7;
  for (let i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--;
    if (pos < 2) pos = 9;
  }
  resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
  if (resultado !== digitos.charAt(1)) return false;

  return true;
};

export const validAddress = ({
  line1,
  line3,
  mcc,
  city,
  state,
  postal_code,
}) => {
  if (mcc === "") {
    return {
      status: false,
      message: "Informe tipo do seu negócio",
    };
  }

  if (line1.length < 4) {
    return {
      status: false,
      message: "Informe seu endereço",
    };
  }

  if (line3.length < 1) {
    return {
      status: false,
      message: "Informe o número do local",
    };
  }

  if (city.length < 4) {
    return {
      status: false,
      message: "Informe sua cidade",
    };
  }

  if (state.length < 2) {
    return {
      status: false,
      message: "Informe seu estado",
    };
  }

  if (postal_code.length < 4) {
    return {
      status: false,
      message: "Informe seu CEP",
    };
  }

  return { status: true };
};

export const validAccount = ({ name, name_tag, email, phone, taxpayer }) => {
  if (name.length < 3)
    return {
      status: false,
      message: "Preencha seu nome",
    };
  if (name_tag.length < 3)
    return {
      status: false,
      message: "Preencha seu crachá",
    };
  if (!EMAIL_REGEX.test(email))
    return {
      status: false,
      message: "Preencha seu email corretamente",
    };
  if (phone.length < 7)
    return {
      status: false,
      message: "Preencha seu telefone corretamente",
    };
  if (!validCPF(taxpayer))
    return {
      status: false,
      message: "Informe um CPF válido",
    };
  return { status: true };
};

export const validCheckout = ({
  amount,
  number,
  holder_name,
  holder_taxpayer,
  exp_month,
  exp_year,
  cvc,
}) => {
  if (holder_name.length < 5)
    return {
      status: false,
      message: "Preencha o nome do titular",
    };
  if (!validCPF(holder_taxpayer))
    return {
      status: false,
      message: "Informe um CPF válido",
    };
  if (exp_month.length < 2)
    return {
      status: false,
      message: "Informe o mês de vencimento",
    };
  if (exp_year.length < 2)
    return {
      status: false,
      message: "Informe o ano de vencimento",
    };
  if (cvc.length < 1)
    return {
      status: false,
      message: "Informe o código de segurança",
    };
  if (number.length < 10)
    return {
      status: false,
      message:
        "Número do cartão inválido. Verifique se você digitou todos os números corretamente.",
    };
  if (amount <= 0)
    return {
      status: false,
      message: "valor da compra inválido",
    };

  return { status: true };
};

export const validCompany = ({
  business_address,
  ein,
  statement_descriptor,
  business_opening_date,
  business_name,
  business_phone,
  business_email,
  business_website,
}) => {
  if (business_address.line1.length < 4)
    return {
      status: false,
      message: "Informe o endereço da empresa",
    };
  if (business_address.line3.length < 1)
    return {
      status: false,
      message: "Informe o número da empresa",
    };
  if (business_address.city.length < 1)
    return {
      status: false,
      message: "Informe o nome da cidade",
    };
  if (business_address.state.length < 1)
    return {
      status: false,
      message: "Informe o nome do estado",
    };
  if (business_opening_date === "")
    return {
      status: false,
      message: "Informe a data de criação da empresa",
    };
  if (business_address.postal_code.length < 4)
    return {
      status: false,
      message: "Informe o CEP da empresa",
    };
  if (!validCNPJ(ein))
    return {
      status: false,
      message: "Informe um CNPJ válido",
    };
  if (business_name === "")
    return {
      status: false,
      message: "Informe o nome da empresa",
    };
  if (business_email.length < 5)
    return {
      status: false,
      message: "Informe o email da empresa",
    };
  if (business_phone.length < 5)
    return {
      status: false,
      message: "Informe o telefone da empresa",
    };
  if (business_website.length < 5)
    return {
      status: false,
      message: "Informe o site da empresa",
    };
  if (statement_descriptor.length < 5)
    return {
      status: false,
      message: "Informe o nome da empresa que aparecerá no boleto do cliente",
    };

  return { status: true };
};

export const checkCardBrand = (number) => {
  return brands.find((element) => {
    if (element.regex.test(number)) {
      return element.name;
    }
  });
};

export const codigoPaises = [
  "+1",
  "+7",
  "+20",
  "+27",
  "+30",
  "+31",
  "+32",
  "+33",
  "+34",
  "+36",
  "+39",
  "+40",
  "+41",
  "+43",
  "+44",
  "+45",
  "+46",
  "+47",
  "+48",
  "+49",
  "+51",
  "+52",
  "+53",
  "+54",
  "+55",
  "+56",
  "+57",
  "+58",
  "+60",
  "+61",
  "+62",
  "+63",
  "+64",
  "+65",
  "+66",
  "+81",
  "+82",
  "+84",
  "+86",
  "+90",
  "+91",
  "+92",
  "+93",
  "+94",
  "+95",
  "+98",
  "+211",
  "+212",
  "+212",
  "+213",
  "+216",
  "+218",
  "+220",
  "+221",
  "+222",
  "+223",
  "+224",
  "+225",
  "+226",
  "+227",
  "+228",
  "+229",
  "+230",
  "+231",
  "+232",
  "+233",
  "+234",
  "+235",
  "+236",
  "+237",
  "+238",
  "+239",
  "+240",
  "+241",
  "+242",
  "+243",
  "+244",
  "+245",
  "+246",
  "+248",
  "+249",
  "+250",
  "+251",
  "+252",
  "+253",
  "+254",
  "+255",
  "+256",
  "+257",
  "+258",
  "+260",
  "+261",
  "+262",
  "+262",
  "+263",
  "+264",
  "+265",
  "+266",
  "+267",
  "+268",
  "+269",
  "+290",
  "+291",
  "+297",
  "+298",
  "+299",
  "+350",
  "+351",
  "+352",
  "+353",
  "+354",
  "+355",
  "+356",
  "+357",
  "+358",
  "+359",
  "+370",
  "+371",
  "+372",
  "+373",
  "+374",
  "+375",
  "+376",
  "+377",
  "+378",
  "+379",
  "+380",
  "+381",
  "+382",
  "+383",
  "+385",
  "+386",
  "+387",
  "+389",
  "+420",
  "+421",
  "+423",
  "+500",
  "+501",
  "+502",
  "+503",
  "+504",
  "+505",
  "+506",
  "+507",
  "+508",
  "+509",
  "+590",
  "+590",
  "+591",
  "+592",
  "+593",
  "+595",
  "+597",
  "+598",
  "+599",
  "+599",
  "+670",
  "+672",
  "+673",
  "+674",
  "+675",
  "+676",
  "+677",
  "+678",
  "+679",
  "+680",
  "+681",
  "+682",
  "+683",
  "+685",
  "+686",
  "+687",
  "+688",
  "+689",
  "+690",
  "+691",
  "+692",
  "+850",
  "+852",
  "+853",
  "+855",
  "+856",
  "+880",
  "+886",
  "+960",
  "+961",
  "+962",
  "+963",
  "+964",
  "+965",
  "+966",
  "+967",
  "+968",
  "+970",
  "+971",
  "+972",
  "+973",
  "+974",
  "+975",
  "+976",
  "+977",
  "+992",
  "+993",
  "+994",
  "+995",
  "+996",
  "+998",
];

export const getCurrentDeviceInfor = () => {
  const colorDepth = window.screen.colorDepth;
  const language = navigator.language || navigator.userLanguage;  
  const timeZoneOffset = -(new Date().getTimezoneOffset() / 60);
  const screenHeight = window.screen.height;
  const screenWidth = window.screen.width;
  const isMobile = /Mobi|Android/i.test(navigator.userAgent);
  const type = isMobile ? "MOBILE" : "DESKTOP";

  return {
    color_depth: colorDepth,
    language: language,
    time_zone_off_set: timeZoneOffset,
    screen_height: screenHeight,
    screen_width: screenWidth,
    type: type
  };
}