import React from 'react';

function CaptorInformation({captor}) {
    return (
        <div className="courseInfoContainer">
            <div className="courseInfoGroup">
                <strong>Consultor</strong>
                <p>{captor.name}</p>
            </div>
        </div>
    );
}

export default CaptorInformation;