import provider from "./config";

export const getCaptor = async (id) => {
    try {
        const response = await provider.get("captor/captor-for-checkout/", {
            params: {
                profile: id
            }
        });
        return response.data;
    } catch (err){
        throw Error("Consultor inválido");
    }
}