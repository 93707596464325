import React, { useState, useEffect } from "react";
import InputMask from "react-input-mask";
import { addToast } from "../Notification";
import ModalLoadingPayment from "../ModalLoadingPayment";
import { validAccount } from "../../utils";
import {
  checkForInscription,
  checkForTaxpayer,
  getByTaxpayer,
  createInscription,
} from "../../providers/trainee";
import { useRef } from "react";
function PersonalData({
  disabled,
  handleUpdate,
  initialState,
  courseClass,
  captor,
  setStep,
  setTraineeData,
  setInscriptionData,
}) {
  const [payload, setPayload] = useState({
    name: "",
    name_tag: "",
    email: "",
    phone: "",
    taxpayer: "",
    sex: "",
  });
  const [trainee, setTrainee] = useState(null);
  const [inscription, setInscription] = useState(null);
  const [hasSearchedTrainee, setHasSearchedTrainee] = useState(false);
  const [hasInscription, setHasInscription] = useState(false);
  const [hasPayedInscription, setHasPayedInscription] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (initialState) {
      setPayload({
        name: initialState.name || "",
        name_tag: initialState.name_tag || "",
        email: initialState.email || "",
        phone: initialState.phone || "",
        taxpayer: initialState.taxpayer || "",
        sex: initialState.sex || "",
      });
    }
  }, [initialState]);

  const handleChangeValue = (field, value) => {
    return setPayload({ ...payload, [field]: value });
  };

  const handleSubmit = async () => {
    setLoading(true);
    if (payload.sex.length <= 0) {
      setLoading(false);
      return addToast("error", "Erro", "Selecione seu sexo");
    }

    let accountPayload = {
      ...payload,
      taxpayer: payload.taxpayer.replace("-", ""),
      phone: payload.phone.replace("(", "").replace(")", "").replace("-", ""),
    };

    let valid = validAccount(accountPayload);
    if (!valid.status) {
      setLoading(false);
      return addToast("error", "Erro", valid.message);
    }

    accountPayload = { ...accountPayload, taxpayer: payload.taxpayer };

    if (inscription) {
      setLoading(false);
      return setInscriptionData(inscription);
    } else if (trainee) {
      setLoading(false);
      return setTraineeData(trainee);
    } else {
      try {
        await checkForTaxpayer(accountPayload.taxpayer);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        return addToast("error", "Erro", err.message);
      }

      return handleUpdate(accountPayload);
    }
  };

  const handleNoPaymentInscription = async () => {
    setLoading(true);
    if (payload.sex.length <= 0) {
      setLoading(false);
      return addToast("error", "Erro", "Selecione seu sexo");
    }

    let accountPayload = {
      ...payload,
      taxpayer: payload.taxpayer.replace("-", ""),
      phone: payload.phone.replace("(", "").replace(")", "").replace("-", ""),
    };

    let valid = validAccount(accountPayload);
    if (!valid.status) {
      setLoading(false);
      return addToast("error", "Erro", valid.message);
    }

    accountPayload = {
      ...accountPayload,
      taxpayer: payload.taxpayer,
      captor_id: captor.id,
      class_id: courseClass.id,
      is_captor_id: true,
      is_trainee: trainee ? true : false,
      value: 0,
    };

    try {
      await createInscription(accountPayload);
      setStep(2);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      return addToast("error", "Erro", err.message);
    }
  };

  const getTrainee = async () => {
    if (payload.taxpayer.trim().length <= 0)
      return addToast("error", "Erro", "Digite um CPF válido");

    try {
      const result = await getByTaxpayer(payload.taxpayer);

      setTrainee(result);
      setTraineeData(result);
      setPayload({
        name: result.name,
        name_tag: result.name_tag,
        email: result.email,
        phone: result.phone,
        taxpayer: result.taxpayer,
        sex: result.sex,
      });
      setHasSearchedTrainee(true);
      getInscription(result.id);
    } catch (err) {
      setTrainee(null);
      setPayload({
        name: "",
        name_tag: "",
        email: "",
        phone: "",
        taxpayer: payload.taxpayer,
        sex: "",
      });
      setHasSearchedTrainee(true);
      setHasInscription(false);
      setHasPayedInscription(false);
      // return addToast("error", "Erro", err.message);
    }
  };

  const getInscription = async (id) => {
    try {
      const result = await checkForInscription(id, courseClass.id);

      if (result.inscripted) {
        setHasInscription(true);

        if (result.payed) {
          setHasPayedInscription(true);
        }

        setInscription({
          id: result.id,
          name: result.name,
          payed: result.payed,
        });
      } else {
        setHasInscription(false);
        setHasPayedInscription(false);
      }
    } catch (err) {
      return addToast("error", "Erro", err.message);
    }
  };

  const checkIfInfoIsOk = () => {
    let infoOk = true;

    for (const key in payload) {
      const value = payload[key];

      if (value.trim().length <= 0) {
        infoOk = false;
        break;
      }
    }

    return infoOk;
  };

  const ref = useRef();

  return (
    <form autoComplete="off" className="box">
      <p>Digite Seus Dados abaixo para Concluir sua Inscrição</p>
      <div className="box taxpayer-container">
        <InputMask
          value={payload.taxpayer}
          placeholder="CPF"
          mask="999999999-99"
          type="tel"
          onChange={({ target }) => handleChangeValue("taxpayer", target.value)}
        />
        <button
          type="button"
          onClick={getTrainee}
          disabled={disabled}
          className="confirm-button"
        >
          Continuar
        </button>
      </div>
      {hasSearchedTrainee && !trainee && (
        <>
          <input
            value={payload.name}
            type="text"
            placeholder="Nome Completo"
            onChange={({ target }) => handleChangeValue("name", target.value)}
          />
          <input
            value={payload.name_tag}
            type="text"
            placeholder="Crachá"
            onChange={({ target }) =>
              handleChangeValue("name_tag", target.value)
            }
          />

          {/* <input value={payload.birth_date}
                    type="text"
                    ref={ref}
                    placeholder="Data de nascimento"
                    onFocus={() => (ref.current.type = "date")}
                    onBlur={() => (ref.current.type = "date")}
                    id="date"onChange={({ target }) => handleChangeValue('birth_date', target.value)} /> */}

          {/* <InputMask
            type="text"
            mask="99/99/9999"
            placeholder="Data de nascimento"
            onChange={({ target }) =>
              handleChangeValue("birth_date", target.value)
            }
          /> */}

          <input
            value={payload.email}
            placeholder="E-mail"
            type="email"
            onChange={({ target }) => handleChangeValue("email", target.value)}
          />
          <InputMask
            value={payload.phone}
            placeholder="Celular com DDD"
            mask="(99)99999-9999"
            type="tel"
            onChange={({ target }) => handleChangeValue("phone", target.value)}
          />
          <select
            style={{ marginBottom: "64px" }}
            className="customSelect"
            value={payload.sex}
            onChange={({ target }) => handleChangeValue("sex", target.value)}
          >
            <option value="">Sexo</option>
            <option value="MASCULINO">Masculino</option>
            <option value="FEMININO">Feminino</option>
          </select>
        </>
      )}
      {trainee && (
        <div className="taxpayer-container">
          <div className="courseInfoContainer">
            <div className="courseInfoGroup">
              <strong>Treinando</strong>
              <p>{trainee.name}</p>
            </div>
            <div className="courseInfoGroup">
              <strong>E-mail</strong>
              <p>{trainee.email}</p>
            </div>
          </div>
        </div>
      )}
      {(trainee || checkIfInfoIsOk()) && !hasPayedInscription && (
        <div className="row button-group">
          {!hasInscription && (
            <button
              onClick={handleNoPaymentInscription}
              type="button"
              className="confirm-button secondary"
            >
              Continuar sem pagamento
            </button>
          )}
          <button
            onClick={handleSubmit}
            style={{ width: hasInscription ? "100%" : "45%" }}
            type="button"
            className="confirm-button primary"
          >
            Pagar inscrição agora
          </button>
        </div>
      )}
      {hasPayedInscription && (
        <div className="box">
          <strong style={{ color: "red" }}>
            Sua inscrição nesta turma já está paga!
          </strong>
        </div>
      )}
      <ModalLoadingPayment isOpen={loading} />
    </form>
  );
}

export default PersonalData;
