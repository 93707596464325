import React from 'react';
import * as image from "../../assets";

function CardBrands() {
  return (
    <>
    <div className="cards-logo">
        <img src={image.ELO_LOGO}/>
        <img src={image.HIPER_CARD_LOGO}/>
        <img src={image.HIPER_LOGO}/>
        <img src={image.JBC_LOGO}/>
        <img src={image.VISA_LOGO} />
    </div>
    <div id="bottom-cards" className="cards-logo">
        <img src={image.MASTER_LOGO} />
        <img src={image.BANES_LOGO} />
        <img src={image.AURA_LOGO} />
        <img src={image.AMERICAN_LOGO} />
    </div>
    </>
  );
}

export default CardBrands;