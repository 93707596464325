import provider from "./config";

export const checkForInscription = async (id, course_class) => {
    try {
        const response = await provider.get(`trainee/check-for-inscription/`, {
            params: {
                id,
                course_class
            }
        });
        return response.data;
    } catch (err){
        throw Error("Treinando inválido");
    }
}

export const checkForTaxpayer = async (taxpayer) => {
    try {
        await provider.get(`trainee/check-for-taxpayer/`, {
            params: {
                taxpayer
            }
        });
        return true;
    } catch (err){
        throw Error("Este CPF já está cadastrado");
    }
}

export const getByTaxpayer = async taxpayer => {
    try {
        const response = await provider.get(`trainee/get-by-taxpayer/`, {
            params: {
                taxpayer
            }
        });
        return response.data;
    } catch (err){
        throw Error("Nenhum treinando cadastrado com este CPF");
    }
}

export const createInscription = async payload => {
    try {
        const response = await provider.post('trainee/inscription/', payload);
        return response.data;
    } catch (err){
        throw Error(err.message);
    }
}