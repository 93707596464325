import React, { useState, useEffect, useRef } from "react";

import PersonalData from "../PersonalData";
import Checkout from "../Checkout";
import Logo from "../Logo";
import AppBar from "../AppBar";
import CourseClassInformation from "../CourseClassInformation";
import CaptorInformation from "../CaptorInformation";
import CardBrands from "../CardBrands";
import ModalLoadingPayment from "../ModalLoadingPayment";
import { addToast } from "../Notification";
import * as image from "../../assets";
import { baseURL } from "../../providers/config";

import * as checkoutService from "../../providers/checkout";
import { transactionPayment } from "../../providers/transaction";
import CourseInformationTransaction from "../CourseInformationTransaction";
import ReactFbq from "react-fbq";
import Time from "../../assets/img/time.webp";
import { getCurrentDeviceInfor } from "../../utils";

const CheckoutForm = ({
  disabled,
  payed,
  trainee,
  inscription,
  transaction,
  courseClass,
  captor,
  facebook_pixel,
}) => {
  const siteURL =
    baseURL === "https://mutase-api.devaribox.co/api/v1"
      ? "https://mutase.devaribox.co"
      : "https://mutase.com.br";
  const [step, setStep] = useState(1);
  const [account, setAccount] = useState(null);
  const [checkout, setCheckout] = useState(null);
  const [isLoadingPayment, setIsLoadingPayment] = useState(false);
  const [traineeData, setTraineeData] = useState(trainee);
  const [inscriptionData, setInscriptionData] = useState(inscription);
  const [captorId, setCaptorId] = useState("");
  const [paymentRedirect, setPaymentRedirect] = useState(null);
  const [contador, setContador] = useState(null);
  const timeClock = useRef();

  useEffect(() => {
    if (account || inscriptionData || traineeData || transaction) {
      setStep(1);
    }
  }, [account, traineeData, inscriptionData, transaction]);

  useEffect(() => {
    if (checkout) {
      handleSubmit();
    }
  }, [checkout]);

  useEffect(() => {
    if (facebook_pixel) {
      const fb_pixel = facebook_pixel;

      if (fb_pixel) {
        ReactFbq.initialize({ id: fb_pixel });
        ReactFbq.pageView();
        ReactFbq.track({ title: "InitiateCheckout", data: {} });
        if (step === 3) {
          ReactFbq.initialize({ id: fb_pixel });
          ReactFbq.pageView();
          ReactFbq.track({ title: "Purchase", data: {} });
        }
      }
    }
  }, [step, facebook_pixel]);

  useEffect(() => {
    if (courseClass) {
      const { payment_redirect } = courseClass;
      if (payment_redirect) {
        setPaymentRedirect(payment_redirect);
      }
    }
  }, [courseClass]);

  useEffect(() => {
    if (transaction) {
      if (transaction.captor_id.length > 0) {
        setCaptorId(transaction.captor_id[0].id);
      } else {
        setCaptorId(-1);
      }
    }
  }, [transaction]);

  useEffect(() => {
    var duration = 60 * 15; // Converter para segundos
    startTimer(duration); // iniciando o timer
  }, []);

  function startTimer(duration) {
    var timer = duration,
      minutes,
      seconds;

    timeClock.current = setInterval(function () {
      minutes = parseInt(timer / 60, 10);
      seconds = parseInt(timer % 60, 10);
      minutes = minutes < 10 ? "0" + minutes : minutes;
      seconds = seconds < 10 ? "0" + seconds : seconds;
      setContador(minutes + ":" + seconds);
      console.log(timer, duration);
      if (--timer < 0) {
        clearTimeout(timeClock.current);
        timeClock.current = null;
      }
    }, 1000);
  }

  const handleSubmit = async () => {
    setIsLoadingPayment(true);

    if (inscriptionData || traineeData) {
      let paymentPayload = { ...checkout };
      if (inscriptionData)
        paymentPayload = {
          ...paymentPayload,
          inscription: inscriptionData.id,
          course_class: courseClass.id,
        };
      if (traineeData)
        paymentPayload = {
          ...paymentPayload,
          trainee: traineeData.id,
          course_class: courseClass.id,
        };

      // try {
      //     await checkoutService.payInscription(paymentPayload);
      //     setIsLoadingPayment(false);
      //     return setStep(3);
      // } catch (err) {
      //     setIsLoadingPayment(false);
      //     return addToast("error", "Erro", err.message);
      // }
    }

    var idClass = "";
    var idCaptor = "";
    var signUpAndPaymentPayload = "";

    let deviceInfor = getCurrentDeviceInfor();

    if (transaction) {
      if (transaction.inscription.trainee.name === "SITE") {
        idCaptor = -1;
      } else {
        idClass = transaction.inscription.course_class.id;
        idCaptor = captorId;
      }
    } else if (captor.name === "SITE") {
      idCaptor = -1;
    } else {
      idClass = courseClass.id;
      idCaptor = captor.id;
    }

    if (transaction) {
      signUpAndPaymentPayload = {
        ...account,
        ...checkout,
        name: transaction ? transaction.inscription.trainee.name : "",
        taxpayer: transaction ? transaction.inscription.trainee.taxpayer : "",
        email: transaction ? transaction.inscription.trainee.email : "",
        course_class: idClass,
        captor_id: captorId ? captorId : idCaptor,
        transaction: transaction.id,
        inscription: transaction.inscription.id,
        trainee: transaction.inscription.trainee.id,
        deviceInfor: deviceInfor
      };
      try {
        await transactionPayment(signUpAndPaymentPayload);
        if (paymentRedirect) {
          window.location.href = `${paymentRedirect}`;
        } else {
          setIsLoadingPayment(false);
          return setStep(3);
        }
      } catch (err) {
        setIsLoadingPayment(false);
        return addToast("error", "Erro", err.message);
      }
    } else if (traineeData) {
      signUpAndPaymentPayload = {
        ...account,
        ...checkout,
        name: checkout ? checkout.holder_name : "",
        taxpayer: checkout ? checkout.holder_taxpayer : "",
        email: checkout ? checkout.email : "",
        course_class: courseClass.id,
        captor_id: captorId ? captorId : idCaptor,
        transaction: transaction ? transaction.id : "",
        inscription: transaction ? transaction.inscription.id : "",
        trainee: traineeData.id,
        deviceInfor: deviceInfor
      };

      try {
        await transactionPayment(signUpAndPaymentPayload);
        if (paymentRedirect) {
          window.location.href = `${paymentRedirect}`;
        } else {
          setIsLoadingPayment(false);
          return setStep(3);
        }
      } catch (err) {
        setIsLoadingPayment(false);
        return addToast("error", "Erro", err.message);
      }
    } else {
      signUpAndPaymentPayload = {
        ...account,
        ...checkout,
        class_id: courseClass.id,
        captor_id: captor.name === "SITE" ? -1 : captor.id,
        deviceInfor: deviceInfor
      };

      try {
        await checkoutService.createInscriptionAndPayment(
          signUpAndPaymentPayload
        );
        if (paymentRedirect) {
          window.location.href = `${paymentRedirect}`;
        } else {
          setIsLoadingPayment(false);
          return setStep(3);
        }
      } catch (err) {
        setIsLoadingPayment(false);
        return addToast("error", "Erro", err.message);
      }
    }
  };

  const renderComponent = () => {
    if (courseClass) {
      switch (step) {
        case 0:
          return (
            <PersonalData
              disabled={
                disabled ? true : courseClass ? courseClass.sold_out : true
              }
              handleUpdate={setAccount}
              initialState={account}
              courseClass={courseClass}
              captor={captor}
              setStep={setStep}
              setTraineeData={setTraineeData}
              setInscriptionData={setInscriptionData}
            />
          );
        case 1:
          return (
            <Checkout
              taxpayer={account ? account.taxpayer : ""}
              disabled={
                disabled ? true : courseClass ? courseClass.sold_out : true
              }
              disableSameTaxpayer={traineeData || inscriptionData}
              price={courseClass ? courseClass.reference_value : 0}
              transaction={transaction ? transaction : null}
              handleUpdate={setCheckout}
              setTraineeData={setTraineeData}
              courseClass={courseClass}
            />
          );
        case 2:
          return (
            <div id="success-container">
              <img src={image.SUCCESS} />
              <br />
              <h4>Inscrição realizada!</h4>
              <a href={siteURL}>Voltar ao site</a>
            </div>
          );
        default:
          return (
            <div id="success-container">
              <img src={image.SUCCESS} />
              <br />
              <h4>Pagamento realizado!</h4>
              <a href={siteURL}>Voltar ao site</a>
            </div>
          );
      }
    }
  };

  return (
    <div id="wrapper">
      {step === 2 && !traineeData && !inscriptionData && (
        <button className="backButton" onClick={() => setStep(1)}>
          Voltar
        </button>
      )}
      {/* <Logo /> */}
      {/* <AppBar step={step} /> */}
      {/* <CardBrands /> */}
      <div className="courseClass">
        <div className="contador">
          <p id="timer">{contador}</p>
          <img
            src={Time}
            width="26"
            height="26"
            alt="Ícone de um relógio"
            loading="eager"
          />
          <span>
            {contador !== "00:00"
              ? "Oferta por tempo limitado"
              : "O tempo acabou!"}
          </span>
        </div>
        <div>
          <div>{courseClass ? <img src={courseClass.cover} /> : null}</div>
          <div className="column">
            {courseClass ? (
              <CourseClassInformation courseClass={courseClass} />
            ) : null}
            {transaction ? (
              <CourseInformationTransaction transaction={transaction} />
            ) : null}
            {captor ? <CaptorInformation captor={captor} /> : null}
          </div>
        </div>
      </div>
      <hr />
      {traineeData || inscriptionData ? (
        <div className="courseInfoContainer">
          <div className="courseInfoGroup">
            <strong>Treinando</strong>
            <p>{inscriptionData ? inscriptionData.name : traineeData.name}</p>
          </div>
        </div>
      ) : null}
      {payed ? (
        <div className="box">
          <strong style={{ color: "red" }}>Sua inscrição já está paga!</strong>
        </div>
      ) : courseClass && courseClass.sold_out ? (
        <div className="box">
          <strong style={{ color: "red" }}>Vagas esgotadas!</strong>
        </div>
      ) : (
        renderComponent()
      )}
      <ModalLoadingPayment isOpen={isLoadingPayment} />
    </div>
  );
};

export default CheckoutForm;
