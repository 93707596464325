import React from 'react';
import {
    Modal as ModalContainer,
    ModalHeader,
    ModalBody,
    Spinner
} from "reactstrap";
import "./styles.css";


function ModalLoadingPayment({isOpen}) {
  return (
    <ModalContainer isOpen={isOpen} size="sm">
        <ModalHeader>Aguarde...</ModalHeader>
        <ModalBody className="d-flex align-items-center">
            <Spinner color="warning" size="sm"/>
            <p className="ml-3 mt-3">Processando</p>
        </ModalBody>
    </ModalContainer>
  );
}

export default ModalLoadingPayment;