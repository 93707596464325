import React from 'react';
import { 
    Modal, 
    ModalHeader, 
    ModalBody, 
    ModalFooter
} from 'reactstrap';

import * as image from "../../assets";

function ModalCCV({modal, triggerModal}) {
  return (
    <Modal isOpen={modal} toggle={triggerModal}>
        <ModalHeader className="justify-content-center">Código de Segurança</ModalHeader>
        <ModalBody className="text-center">
            <p>Digite o código de segurança (CVV) de 3 ou 4 dígitos presente no verso do cartão.</p>
            <img src={image.CARD} />
        </ModalBody>
        <ModalFooter className="justify-content-center">
            <button className="confirm-button" color="primary" onClick={triggerModal}>Continuar</button>
        </ModalFooter>
    </Modal>
  );
}

export default ModalCCV;