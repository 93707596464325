import provider from "./config";
import { authorization } from "./config"

export const getTransaction = async (id) => {
  try {
    const response = await provider.get(`transaction/${id}/`,
      { headers: { Authorization: `Token ${authorization}` } });
    return response.data;
  } catch (err) {
    console.log(err)
    throw Error("Transação inválida");
  }
}

export const transactionPayment = async (payload) => {

  try {
    const response = await provider.post("transaction/checkout/", payload);
    return response.data;
  } catch (err) {
    if (err && err.response && err.response.data) {
      if (err.response.data.error.replace(/'/g, "") === "id") {
        throw Error("Ocorreu um erro ao realizar o pagamento. Verifique as informação do seu cartão.")
      }
    }
    throw Error("Ocorreu um erro ao realizar o pagamento");
  }
}