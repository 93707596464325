import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import CheckoutForm from "../components/CheckoutForm";
import { addToast } from "../components/Notification";

import { getCourseClass } from "../providers/course";
import { getCaptor } from "../providers/captor";
import { checkForInscription } from "../providers/trainee";
import { getTransaction } from "../providers/transaction";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const CustomerCheckout = () => {
  const query = useQuery();
  const [courseClass, setCourseClass] = useState(null);
  const [captor, setCaptor] = useState(null);
  const [trainee, setTrainee] = useState(null);
  const [facebook_pixel, setFacebook_pixel] = useState(null);
  const [transaction, setTransaction] = useState(null);
  const [inscription, setInscription] = useState(null);
  const [inscriptionPayed, setInscriptionPayed] = useState(false);
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    loadContent();
  }, []);

  useEffect(() => {
    if (courseClass) {
      const { head_trainer } = courseClass;
      if (head_trainer && head_trainer.facebook_pixel) {
        setFacebook_pixel(head_trainer.facebook_pixel);
      }
    }
  }, [courseClass]);

  const loadContent = async () => {
    const courseClassId = query.get("class");
    const captorId = query.get("captor");
    const traineeId = query.get("trainee");
    const transactionId = query.get("transaction");

    if (courseClassId) {
      await loadCourse(courseClassId);
    }

    if (captorId) {
      await loadCaptor(captorId);
    }

    if (traineeId) {
      await loadTrainee(traineeId, courseClassId);
    }

    if (transactionId) {
      await loadTransaction(transactionId);
    }
  };

  const loadCourse = async (courseClassId) => {
    try {
      const course = await getCourseClass(courseClassId);

      return setCourseClass(course);
    } catch (err) {
      setDisabled(true);
      return addToast("error", "Erro", err.message);
    }
  };

  const loadCaptor = async (captorId) => {
    try {
      const captor = await getCaptor(captorId);
      return setCaptor(captor);
    } catch (err) {
      setDisabled(true);
      return addToast("error", "Erro", err.message);
    }
  };

  const loadTrainee = async (traineeId, courseClassId) => {
    try {
      const result = await checkForInscription(traineeId, courseClassId);

      if (result.inscripted) {
        if (result.payed) {
          setInscriptionPayed(true);
          return setDisabled(true);
        } else
          return setInscription({
            id: result.id,
            name: result.name,
          });
      } else {
        setTrainee({
          id: traineeId,
          name: result.name,
        });
      }
    } catch (err) {
      setDisabled(true);
      return addToast("error", "Erro", err.message);
    }
  };

  const loadTransaction = async (transactionId) => {
    try {
      const resp = await getTransaction(transactionId);
      return setTransaction(resp);
    } catch (err) {
      setDisabled(true);
      return addToast("error", "Erro", err.message);
    }
  };

  return (
    <CheckoutForm
      disabled={disabled}
      payed={inscriptionPayed}
      trainee={trainee}
      inscription={inscription}
      transaction={transaction}
      courseClass={courseClass}
      facebook_pixel={facebook_pixel}
      captor={captor}
    />
  );
};

export default CustomerCheckout;
