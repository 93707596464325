import provider from "./config";

export const createInscriptionAndPayment = async (payload) => {
    try {
        const response = await provider.post("transaction/signup-and-checkout/", payload);
        return response.data;
    } catch (err) {
        if (err && err.response && err.response.data) {
            if (err.response.data.error.replace(/'/g, "") === "id") {
                throw Error("Ocorreu um erro ao realizar o pagamento. Verifique as informação do seu cartão.")
            }
        }
        throw Error("Ocorreu um erro ao realizar o pagamento");
    }
}

export const payInscription = async (payload) => {
    try {
        const response = await provider.post("transaction/checkout/", payload);
        return response.data;
    } catch (err) {
        if (err && err.response && err.response.data) {
            if (err.response.data.error.replace(/'/g, "") === "id") {
                throw Error("Ocorreu um erro ao realizar o pagamento. Verifique as informação do seu cartão.")
            }
        }
        throw Error("Ocorreu um erro ao realizar o pagamento");
    }
}