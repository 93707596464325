import React from "react";
import { toReal } from "../../utils";

function CourseClassInformation({ courseClass }) {
  return (
    <div className="courseInfoContainer">
      <div className="courseInfoGroup">
        <strong>Turma</strong>
        <p>{courseClass.name}</p>
      </div>
      {/* <div className="courseInfoGroup">
                <strong>Head Trainer</strong>
                <p>{courseClass.head_trainer.name}</p>
            </div> */}
      <div className="courseInfoGroup">
        {/* <strong>Valor da Inscrição</strong> */}
        <p className="primary">
          {/* R${" "} */}
          {courseClass && courseClass.reference_value
            ? toReal(+courseClass.reference_value)
            : "R$ 0,00"}
        </p>
      </div>
      {/* <div className="courseInfoGroup">
                <strong>Vagas Esgotadas</strong>
                <p>{courseClass.sold_out ? "Sim" : "Não"}</p>
            </div> */}
    </div>
  );
}

export default CourseClassInformation;
